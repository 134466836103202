import React, { useState,useRef } from "react";
import emailjs from '@emailjs/browser';

// import Footer from './Footer.js';

import './Home.css';



// import HomeBody from './HomeBody.js';
import './App.css'


import Logop from './Assets/logowhite.png'
import Logoblack from './Assets/logo.png'
import Closeicon from './Assets/close-icon.png'
import Menuicon from './Assets/menuicon.png'
import Innovation from './Assets/innovation.png'
import Development from './Assets/develoopment.png'
import Branding from './Assets/branding.png'
import SoliditySer from './Assets/solid.jpeg'
import MetaSer from './Assets/vrnrgy003.jpg'
import CoinSer from './Assets/coinchain.jpeg'
import TradingSer from './Assets/trading-robort.jpeg'
import NftSer from './Assets/nft-marketplace.jpeg'
import BlockchainSer from './Assets/bcar.jpeg'
import SwapsSer from './Assets/0_3gcisU6O8ARAjklo.jpeg'
import ExchangeSer from './Assets/exchanges.jpeg'
import MernLogo from './Assets/Swiper/MERN-logo.png'
import MeanLogo from './Assets/Swiper/mean-stack.png'
import MqlLogo from './Assets/Swiper/mql.png'
import JamstackLogo from './Assets/Swiper/jamstack.png'
import GraphqlLogo from './Assets/Swiper/graphql.png'
import ChakrauiLogo from './Assets/Swiper/chakra-ui.png'
import VuejsLogo from './Assets/Swiper/vuejs-logo.png'
import NextjsLogo from './Assets/Swiper/nextjs-logo.png'
import NuxtjsLogo from './Assets/Swiper/nuxtjs_logo.png'
import ReactjsLogo from './Assets/Swiper/reactjs-logo.png'
import SolidityLogo2 from './Assets/Swiper/solidity-logo.png'
import MongodbLogo from './Assets/Swiper/mongodb-logo.png'
import NodejsLogo from './Assets/Swiper/node.js_logo.png'
import Discord from './Assets/discord-icon.png'
import Linkdin from './Assets/linkdin-icon.png'
import Telegram from './Assets/telegram-icon.png'
import Twitter from './Assets/twitter-icon.png'
import Banner from './Assets/logowhite.png'




export default function Home() {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_fiozkvt', 'template_mn39rpy', form.current, '91kXZ8tXFtA9q-RGy')
            .then
            (
                (result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                }
            );
            newmsgbox(2);
            e.target.reset();
    };
    const [imgsrc,newimgsrc]=useState(SoliditySer);
    const [imgsrc2,newimgsrc2]=useState(NftSer);
    
    const [check,newcheck]=useState(1);
    const [check2,newcheck2]=useState(1);
    
    const [imgclass,newimgclass]= useState("col-lg-7 opc opcimg1")
    const [imgclass2,newimgclass2]= useState("col-lg-7 order-lg-first opc opcimg4")

    const [msgbox,newmsgbox]=useState(1);

    const [sideb,newsideb]= useState(false)

    return(
        <>
        <div className="bgblack">
            <div className="ml-7 mr-7 mb-3 w-web">
                <div className="d-flex lign-items-center">
                    <div className="mt-3 ml-auto">
                        <img src={Menuicon} className='weblogo menubar' onClick={()=>newsideb(true)}></img>
                    </div>
                </div>
            </div>
            <div className="h-90 back-gif d-flex align-items-center">
                <div className="ml-7 mr-7 p-3 Text-white">
                    <div className="d-flex justify-content-center">
                        <img src={Banner} />
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <h1><b>Blockchain Development Company</b></h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <p>WE VALUES IDEAS THAT ARE INSPIRED, INTELLINGET, AND CREATIVE. WE ARE STRONGER
                            WHEN WORKING AS A UNIT. TO PROVIDE FUTURE VALUE, WE LOOK BEYOND THE PRESENT.
                            WITH SPEED, ATTENTION, AND AN OPEN MIND, WE CHALLENGE CONVENTIONAL DEVELOPMENT
                            METHODS.</p>
                    </div>
                </div>
            </div>
            <div className="pt-8">
                <div className="ml-7 mr-7">
                    <div className="row p-0">
                        <div className="col-lg-4 p-md-3 pt-3 Text-white">
                            <img className="core" src={Innovation}></img>
                            <h3 className="mt-1">Innovation</h3>
                            <p className="mt-2">The only way to discover the limits of the possible is to go beyond them into the impossible.</p>
                        </div>
                        <div className="col-lg-4 p-md-3 pt-3 Text-white">
                            <img className="core" src={Development}></img>
                            <h3 className="mt-1">Development</h3>
                            <p className="mt-2">Let us take you into a deeper experience, make a moment a lasting conveyable memory. Let us help build your tribe.</p>
                        </div>
                        <div className="col-lg-4 p-md-3 pt-3 Text-white">
                            <img className="core" src={Branding}></img>
                            <h3 className="mt-1">Branding</h3>
                            <p className="mt-2">It takes many years to build a reputation and five minutes to ruin it. If you think about that, you'll do things differently.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-8">
                <div className="ml-7 mr-7">
                    <div className="row p-0 m-0 Text-white">
                        
                        <div className="col-lg-4 p-md-4 p-0">
                            <p className="beleive">Our Believe</p>
                            <h2>In the world of Crypto, community is everything.</h2>
                            <p className="mt-7">Are you trying to build a blockchain community that is actually interested in your project, engages in your community, and helps your project through critical growth stages? We know what it takes to foster an authentic community behind your project--we're the experts.</p>
                        </div>
                        <div className="col-lg-4 p-0 m-md-0 mt-2">
                            <div className="h-100 p-2">
                                <div className="h-100 back-mql">
                                    <div className="h-100 w-100 beld p-2 d-flex align-items-start flex-column">
                                        <h2 className="mb-auto">MQL</h2>
                                        <p>Programmers cube will develop MetaTrader Robot for Auto Trading. Now, you can easily turn any manual trading system into an Expert Advisor (Robot) for MetaTrader 4 & 5.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 p-0 m-md-0 mt-2">
                            <div className="h-100 p-2">
                                <div className="h-100 back-blockchain">
                                    <div className="h-100 w-100 beld p-2 d-flex align-items-start flex-column">
                                        <h2 className="mb-auto">Blockchain</h2>
                                        <p>Explore your possibilities of tailored Blockchain solutions for your company with our combined industry experience, knowledge, and technological competence. High-end, technologically advanced solutions with us.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-8">
                <div className="ml-7 mr-7">
                    <div className="row bd-2">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/4x7XV-U0gBQ" title="YouTube video player" frameborder="0px" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div className="pt-8">
                <div className="ml-7 mr-7 Text-white">
                    <div className="row">
                        <div className="col-lg-5 p-4">
                            <div className='hovbd' onMouseEnter={()=>newcheck('1')}>
                                <div onMouseEnter={()=>newimgclass('col-lg-7 opc opcimg1')}>
                                    <div className={check=='1' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc(SoliditySer)}>
                                        <h2>SOLIDITY WEB 3.O</h2>
                                        <p>Solidity is a programming language for writing smart contracts running on Ethereum Block chain.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='hovbd' onMouseEnter={()=>newcheck('2')}>
                                <div onMouseEnter={()=>newimgclass('col-lg-7 opc opcimg2')}>
                                    <div className={check=='2' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc(MetaSer)}>
                                        <h2>METAVERSE</h2>
                                        <p>A virtual-reality space in which users can interact with a computer-generated environment and other users.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="hovbd" onMouseEnter={()=>newcheck('3')}>
                                <div onMouseEnter={()=>newimgclass('col-lg-7 opc opcimg3')}>
                                    <div className={check=='3' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc(BlockchainSer)}>
                                        <h2>BLOCKCHAIN ARCHITECTURE</h2>
                                        <p>Record a history of transactions in a shared, immutable blockchain ledger for transactional applications. Transactions are trusted, accountable, and transparent.</p>
                                    </div>
                                </div>
                            </div>
                            <div onMouseEnter={()=>newcheck('4')}>
                                <div onMouseEnter={()=>newimgclass('col-lg-7 opc opcimg4')}>
                                    <div className={check=='4' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc(TradingSer)}>
                                        <h2>TRADING ROBOT MT4 & MT5</h2>
                                        <p>One Time Setup, Risk Free. Result 24 Hr, No Knowledge Required, Call Us for Installation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={imgclass} style={{backgroundImage: `url(${imgsrc})`,backgroundPosition: 'center',backgroundSize: 'cover'}}>
                        </div>
                    </div>
                    <div className="row pt-8">
                        <div className="col-lg-5 p-4">
                            <div className="hovbd" onMouseEnter={()=>newcheck2('1')}>
                                <div onMouseEnter={()=>newimgclass2('col-lg-7 order-lg-first opc opcimg5')}>
                                    <div className={check2=='1' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc2(NftSer)}>
                                        <h2>NFT MARKETS</h2>
                                        <p>Solidity is a programming language for writing smart contracts running on Ethereum Block chain.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="hovbd" onMouseEnter={()=>newcheck2('2')}>
                                <div onMouseEnter={()=>newimgclass2('col-lg-7 order-lg-first opc opcimg6')}>
                                    <div className={check2=='2' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc2(CoinSer)}>
                                        <h2>COIN DEVELOPMENT</h2>
                                        <p>Cryptocurrency uses blockchain, a secure, unalterable, decentralized ledger to perform a smooth flow of transactions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="hovbd" onMouseEnter={()=>newcheck2('3')}>
                                <div onMouseEnter={()=>newimgclass2('col-lg-7 order-lg-first opc opcimg7')}>
                                    <div className={check2=='3' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc2(SwapsSer)}>
                                        <h2>SWAPS</h2>
                                        <p>Swap is a derivative contract through which two parties exchange the cash flows or liabilities from two different financial instruments.</p>
                                    </div>
                                </div>
                            </div>
                            <div onMouseEnter={()=>newcheck2('4')}>
                                <div onMouseEnter={()=>newimgclass2('col-lg-7 order-lg-first opc opcimg8')}>
                                    <div className={check2=='4' ? 'textser pt-4 pb-4 p-1' : 'textse pt-4 pb-4 p-1'} onMouseEnter={()=>newimgsrc2(ExchangeSer)}>
                                        <h2>EXCHANGES</h2>
                                        <p>A crypto exchange is a platform on which you can buy and sell cryptocurrency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={imgclass2} style={{backgroundImage: `url(${imgsrc2})`,backgroundPosition: 'center',backgroundSize: 'cover'}}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-8">
                    <marquee width='100%' direction="left">
                        <img src={MernLogo} className="imgselmn" alt=""/>
                        <img src={MeanLogo} className="imgselmn" alt=""/>
                        <img src={MqlLogo} className="imgsel" alt=""/>
                        <img src={JamstackLogo} className="imgsel" alt=""/>
                        <img src={GraphqlLogo} className="imgsel" alt=""/>
                        <img src={ChakrauiLogo} className="imgselch" alt=""/>
                        <img src={VuejsLogo} className="imgselvu" alt=""/>
                        <img src={NextjsLogo} className="imgselnt" alt=""/>
                        <img src={NuxtjsLogo} className="imgselnxt" alt=""/>
                        <img src={ReactjsLogo} className="imgseljs" alt=""/>
                        <img src={SolidityLogo2} className="imgselsd" alt=""/>
                        <img src={MongodbLogo} className="imgsel" alt=""/>
                        <img src={NodejsLogo} className="imgselnj" alt=""/>
                    </marquee>
            </div>
            <footer className='footer-back'>
        <div className='row m-0 pt-8 pb-4'>
          <div className='col-lg-4 p-3 d-flex align-items-center justify-content-center'>
            <div className='logo'>
              <div className='d-flex justify-content-center'>
                <img src={Logop} className='logoimg'></img>
              </div>
              <div className='d-flex justify-content-center mt-1'>
                <img src={Discord} className='icon-img'/>
                <img src={Linkdin} className='icon-img'/>
                <img src={Telegram} className='icon-img'/>
                <img src={Twitter} className='icon-img'/>
              </div>
            </div>
          </div>
          <div className='col-lg-4 p-3 text-center d-flex align-items-center justify-content-center'>
            <div className='p-4 bd-style'>
              <h2>Our Vission</h2>
              <p>Delivering AI and Blockchain solutions to drive business outcomes for startups and enterprises.</p>
            </div>
          </div>
          <div className='col-lg-4 p-3 d-flex align-items-center justify-content-center'>
              <div className='W-fit p-4'>
                  {msgbox==1 && 
                  <form  className='p-2' ref={form} onSubmit={sendEmail}>  
                      <div className='row'>
                          <div className='col-6'>
                          <input className='input-form' type='text' placeholder='username' name="user_name" required></input>
                          </div>
                          <div className='col-6'>
                          <input className='input-form' type='email' placeholder='email' name="user_email" required></input>
                          </div>
                      </div>
                      <div className='row mt-3'>
                          <div className='col-6'>
                          <input className='input-form' type='number' placeholder='phone number' name="phonenumber" required></input>
                          </div>
                          <div className='col-6'>
                          <input className='input-form' type='text' placeholder='subject' name="subject" required></input>
                          </div>
                      </div>
                      <div className='row mt-3'>
                          <div className='col-12'>
                          <textarea className='input-form' type='text' placeholder='message' name="message" required></textarea>
                          </div>
                      </div>
                      <div className='mt-3 d-flex justify-content-end'>
                          <input className='buttonall' type='submit' value='Send Message'></input>
                      </div>
                  </form>
                  }
                  {msgbox==2&&
                  <div className="p-2">
                      <p>Thank you for your message. It has been sent.</p>
                  </div>
                  }
                  
                  
              </div>
              
          </div>
        </div>
        <div className='row m-0 text-center'>
            <p>All Rights Reserved By <b>ProgrammersCube®</b></p>
          </div>
        </footer>
        </div>
        {sideb==true&&
            <div className='fix vh-100 slidebar p-4'>
                <div className='h-100'>
                    <img src={Closeicon} className='sidebar-closed' onClick={()=>newsideb(false)}></img>
                    <div className='h-100 d-flex align-items-center'>
                        <div className='text-center'>
                        <img src={Logoblack} className='logoimg fliplog'></img>
                        {msgbox==1&&
                        <form className='p-2' >
                            <div className='row'>
                                <div className='col-6'>
                                    <input className='sidebar-form' type='text' placeholder='username' name="user_name" required></input>
                                </div>
                                <div className='col-6'>
                                    <input className='sidebar-form' type='email' placeholder='email' name="user_email" required></input>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <input className='sidebar-form' type='text' placeholder='phone number' name="phonenumber" required></input>
                                </div>
                                <div className='col-6'>
                                    <input className='sidebar-form' type='text' placeholder='subject' name="subject" required></input>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-12'>
                                    <textarea className='sidebar-form' type='text' placeholder='message' name="message" required></textarea>
                                </div>
                            </div>
                            <div className='mt-3 d-flex justify-content-end'>
                                <input className='buttonall' type='submit' value='Send Message'></input>
                            </div>
                        </form>
                        }
                        {msgbox==2&&
                        <div className="p-2">
                            <p>Thanks you for your message. It has been sent.</p>
                        </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        }
        
        </>
            
    )
}